import React, { useState, useEffect } from "react";
import { Row, Col, Button, Progress } from "reactstrap";
import { TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";

import AviatorFrame from "components/AviatorFrame/AviatorFrame";

import star from "assets/img/star.svg";
import bomb from "assets/img/bomb.svg";
import AnimatedMessage from "components/AnimatedMessage/AnimatedMessage";
import OnlineUsers from "components/OnlineUsers/OnlineUsers";
import { Enviroments } from "enviroments/enviroments";

const MinesSimulator = () => {
  const [signal, setSignal] = useState(null);
  const [cooldown, setCooldown] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  // Gerar locais seguros aleatórios
  const generateSafeSpots = () => {
    const totalSpots = 25; // Total de quadrados no jogo (5x5 grid)
    const allSpots = Array.from({ length: totalSpots }, (_, i) => i + 1);
    const safeSpots = [];

    while (safeSpots.length < 3) {
      const randomSpot = allSpots[Math.floor(Math.random() * allSpots.length)];
      if (!safeSpots.includes(randomSpot)) {
        safeSpots.push(randomSpot);
      }
    }

    return safeSpots;
  };

  const handleRequestSignal = () => {
    setLoading(true);
    setShowLoader(true);
    setCooldown(90); // Inicia o cooldown de 90 segundos
    setSignal(null); // Limpar o estado para que o grid inteiro mostre o loader
    setTimeout(() => {
      const newSignal = generateSafeSpots();
      setSignal(newSignal);
      setLoading(false);
      setTimeout(() => {
        setShowLoader(false); // Desativar o loader após 4 segundos
      }, 4000);
    }, 3000);
  };

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => setCooldown((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [cooldown]);

  return (
    <div className="content mt-0 ml-0 mr-0 pl-1 pr-1">
        <Row className="d-flex justify-content-center align-items-center w-100 mx-auto">
        <Col xs={3}>
          <Link to="signals">
            <div className="d-flex justify-content-center align-items-center">
              <i aria-hidden="true" className="fas fa-arrow-left fs-xxl"></i>
              <span className="ml-2">voltar</span>
            </div>
          </Link>
        </Col>
        <Col xs={9}>
          <h3 className="title m-0 text-right">I.A. Mines PRO</h3>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center mt-2">
        <Col xs="auto" className="text-center">
          <AnimatedMessage />
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center mt-2">
        <Col xs="auto" className="text-center">
          <OnlineUsers />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs="12" md="8" className="text-center">
          <p className="text-muted">
            Clique em "Solicitar Sinal" para receber uma recomendação de locais seguros.
          </p>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mt-3">
        <Col xs="12" md="6" className="text-center">
          <Button
            color="primary"
            onClick={handleRequestSignal}
            disabled={cooldown > 0 || loading}
          >
            {loading ? "Buscando sinal..." : "Solicitar Sinal"}
          </Button>
        </Col>
      </Row>
      {signal && !showLoader && (
        <Row className="d-flex justify-content-center mt-4">
          <Col xs="12" md="6" className="text-center">
            <h4 className="text-success">SINAL CONFIRMADO</h4>
            <p>
              Dica: Defina o jogo com apenas <strong>3 minas</strong> ativas para maior segurança.
            </p>
          </Col>
        </Row>
      )}
      <Row className="d-flex justify-content-center mt-4">
        <Col xs="12" md="12" className="d-flex justify-content-center">
          <div
            className="grid-container"
            style={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gap: "10px" }}
          >
            {Array.from({ length: 25 }, (_, index) => {
              const spotNumber = index + 1;
              const isSafe = signal?.includes(spotNumber);
              return (
                <div
                  key={index}
                  className="grid-item"
                  style={{
                    width: "50px",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    background: isSafe ? "#d4edda" : "#f8d7da",
                  }}
                >
                  {showLoader ? (
                    <TailSpin height="25" width="25" color="#e14eca" />
                  ) : isSafe ? (
                    <img width={35} src={star} alt="Safe Spot" />
                  ) : (
                    <img width={35} src={bomb} alt="Bomb Spot" />
                  )}
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
      {cooldown > 0 && (
        <Row className="d-flex justify-content-center mt-3">
          <Col xs="12" md="6" className="text-center">
            <p className="text-danger">
              Tempo para próximo sinal: {Math.floor(cooldown / 60)}:
              {(cooldown % 60).toString().padStart(2, "0")}
            </p>
            <Progress value={((120 - cooldown) / 120) * 100} />
          </Col>
        </Row>
      )}

      <Row className="d-flex justify-content-center align-items-center mt-4">
        <Col xs="auto">
          <AviatorFrame title="Aviator" src={Enviroments.IFRAME_MINES} />
        </Col>
      </Row>
    </div>
  );
};

export default MinesSimulator;
