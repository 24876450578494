import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Button } from "reactstrap";
import { TailSpin } from "react-loader-spinner";
import AviatorFrame from "components/AviatorFrame/AviatorFrame";
import { Link } from "react-router-dom";
import moment from "moment";

import AnimatedMessage from "components/AnimatedMessage/AnimatedMessage";
import OnlineUsers from "components/OnlineUsers/OnlineUsers";

const AviatorPRO = () => {
  const dateNow = moment(new Date()).format("YYYY-MM-DD");
  const [historico, setHistorico] = useState([]);
  const [sinal, setSinal] = useState(null);
  const [loading, setLoading] = useState(false);

  const buscarHistorico = async () => {
    setLoading(true);
    try {
      const params = {
        date: dateNow,
        numberVelas: 100,
        betHouse: "MINES_BET",
      };

      const response = await axios.get(
        "https://api-aviator-cb5db3cad4c0.herokuapp.com/history-odd",
        { params }
      );

      const data = response.data;
      setHistorico(data);

      const now = new Date();
      const horarioAtual = `${now.getHours().toString().padStart(2, "0")}:${now.getMinutes()
        .toString()
        .padStart(2, "0")}:${now.getSeconds().toString().padStart(2, "0")}`;

      const resultado = analisarHistorico(data, horarioAtual);
      setSinal(resultado);
    } catch (error) {
      console.error("Erro ao buscar histórico:", error);
    } finally {
      setLoading(false);
    }
  };

  const analisarHistorico = (dados, horarioAtual) => {
    if (!dados || dados.length === 0) {
      return {
        mensagem: 'Nenhum dado disponível.',
        mediaHorarios3X: 'N/A',
        proximoHorario3X: 'N/A',
        mediaHorarios10X: 'N/A',
        proximoHorario10X: 'N/A',
      };
    }
  
    // Função para cálculo genérico de multiplicadores
    const calcularHorarios = (filtroMultiplicador) => {
      const multiplicadores = dados.filter((item) => parseFloat(item.odd) > filtroMultiplicador);
  
      if (multiplicadores.length === 0) {
        return {
          mediaHorarios: 'N/A',
          proximoHorario: 'N/A',
        };
      }
  
      multiplicadores.sort((a, b) => {
        const [horaA, minutoA, segundoA] = a.hour.split(':').map(Number);
        const [horaB, minutoB, segundoB] = b.hour.split(':').map(Number);
        return (
          horaA * 3600 + minutoA * 60 + segundoA -
          (horaB * 3600 + minutoB * 60 + segundoB)
        );
      });
  
      const horariosEmMinutos = multiplicadores.map((item) => {
        const [hora, minuto, segundo] = item.hour.split(':').map(Number);
        return hora * 60 + minuto + segundo / 60;
      });
  
      const [horaAtual, minutoAtual, segundoAtual] = horarioAtual.split(':').map(Number);
      const horarioAtualEmMinutos = horaAtual * 60 + minutoAtual + segundoAtual / 60;
  
      const intervalos = horariosEmMinutos
        .map((item, index) =>
          index === 0 ? 0 : item - horariosEmMinutos[index - 1]
        )
        .filter((valor) => valor > 0);
  
      const intervaloMedio =
        intervalos.length > 0
          ? intervalos.reduce((a, b) => a + b, 0) / intervalos.length
          : 0;
  
      if (intervaloMedio === 0) {
        return {
          mediaHorarios: 'N/A',
          proximoHorario: 'N/A',
        };
      }
  
      let proximoMinuto = horariosEmMinutos[horariosEmMinutos.length - 1] + intervaloMedio;
  
      while (proximoMinuto <= horarioAtualEmMinutos) {
        proximoMinuto += intervaloMedio;
      }
  
      const proximoHorarioEmMinutos = proximoMinuto % 1440;
      const proximaHora = Math.floor(proximoHorarioEmMinutos / 60);
      const proximoMinutoRestante = Math.floor(proximoHorarioEmMinutos % 60);
      const proximoSegundo = Math.round((proximoHorarioEmMinutos % 1) * 60);
  
      const proximoHorario = `${proximaHora
        .toString()
        .padStart(2, '0')}:${proximoMinutoRestante
        .toString()
        .padStart(2, '0')}:${proximoSegundo.toString().padStart(2, '0')}`;
  
      const mediaMinutos =
        horariosEmMinutos.reduce((a, b) => a + b, 0) / horariosEmMinutos.length;
  
      const mediaHoras = Math.floor(mediaMinutos / 60);
      const mediaMinutosRestantes = Math.floor(mediaMinutos % 60);
      const mediaSegundos = Math.round((mediaMinutos % 1) * 60);
  
      const mediaHorarios = `${mediaHoras
        .toString()
        .padStart(2, '0')}:${mediaMinutosRestantes
        .toString()
        .padStart(2, '0')}:${mediaSegundos.toString().padStart(2, '0')}`;
  
      return {
        mediaHorarios,
        proximoHorario,
      };
    };
  
    // Cálculos para 3X e 10X
    const resultado3X = calcularHorarios(3);
    const resultado10X = calcularHorarios(10);
  
    return {
      mensagem: 'Cálculo realizado com sucesso.',
      mediaHorarios3X: resultado3X.mediaHorarios,
      proximoHorario3X: resultado3X.proximoHorario,
      mediaHorarios10X: resultado10X.mediaHorarios,
      proximoHorario10X: resultado10X.proximoHorario,
    };
  };

  return (
    <div className="content d-flex flex-column justify-content-center align-items-center mt-4 pt-4 pl-2 pr-2">
      <Row className="d-flex justify-content-center align-items-center w-100 mx-auto">
        <Col xs={3}>
          <Link to="signals">
            <div className="d-flex justify-content-center align-items-center">
              <i aria-hidden="true" className="fas fa-arrow-left fs-xxl"></i>
              <span className="ml-2">voltar</span>
            </div>
          </Link>
        </Col>
        <Col xs={9}>
          <h3 className="title m-0 text-right">I.A. PRO</h3>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center mt-2">
        <Col xs="auto" className="text-center">
          <AnimatedMessage />
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center mt-2">
        <Col xs="auto" className="text-center">
          <OnlineUsers />
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center mt-2">
        <Col xs="auto">
          <Button color="primary" onClick={buscarHistorico} disabled={loading}>
            {loading ? <TailSpin size="sm" /> : "Analisar Horários"}
          </Button>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center mt-4">
        <Col xs="auto">
          <p className="category text-center">
            Horário estimado para velas acima de <strong style={{color: 'yellow'}}>2X à 3X</strong>:
            <h1>
              <strong style={{ color: "#913ef8" }}>
                {sinal?.proximoHorario3X || "N/A"}
              </strong>
            </h1>
          </p>
          <p className="category text-center">
            Horário estimado para velas acima de <strong style={{color: 'yellow'}}>5X à 10X</strong>:
            <h1>
              <strong style={{ color: "#e14eca" }}>
                {sinal?.proximoHorario10X || "N/A"}
              </strong>
            </h1>
          </p>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center mt-2">
        <Col xs="auto">
          <AviatorFrame title="Aviator" src="https://go.aff.br4-partners.com/l59wgsqt" />
        </Col>
      </Row>
    </div>
  );
};

export default AviatorPRO;
