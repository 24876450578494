import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import OnlineUsers from "components/OnlineUsers/OnlineUsers";

const CashMania = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [signal, setSignal] = useState(null);
  const [countdown, setCountdown] = useState(0);

  const getRandomEntries = () => {
    const normalEntries = Math.floor(Math.random() * (18 - 5 + 1)) + 5;
    const turboEntries = Math.floor(Math.random() * (18 - 5 + 1)) + 5;
    return { normalEntries, turboEntries };
  };

  const requestSignal = () => {
    setIsLoading(true);
    setTimeout(() => {
      const { normalEntries, turboEntries } = getRandomEntries();
      setSignal({
        normal: normalEntries,
        turbo: turboEntries,
      });
      setCountdown(120); // 2 minutes countdown
      setIsLoading(false);
    }, 3000);
  };

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else {
      setSignal(null);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <Container className="mt-4 pt-4">
      <Row className="d-flex justify-content-center align-items-center w-100 mx-auto">
        <Col xs={3}>
          <Link to="signals">
            <div className="d-flex justify-content-center align-items-center">
              <i aria-hidden="true" className="fas fa-arrow-left fs-xxl"></i>
              <span className="ml-2">voltar</span>
            </div>
          </Link>
        </Col>
        <Col xs={9}>
          <h3 className="title m-0 text-right">CashMania PRO</h3>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center mt-2">
        <Col xs="auto" className="text-center">
          <OnlineUsers />
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs="12" md="8" className="text-center">
          {!signal && countdown === 0 && (
            <p className="text-muted">Clique em "Solicitar Sinal" para começar.</p>
          )}
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs="12" md="6" className="text-center">
          <Button
            color="primary"
            className="btn-block"
            onClick={requestSignal}
            disabled={isLoading || countdown > 0}
          >
            {isLoading ? (
              <Spinner size="sm" />
            ) : countdown > 0 ? (
              `Aguarde ${countdown} segundos`
            ) : (
              "Solicitar Sinal"
            )}
          </Button>
        </Col>
      </Row>

      {signal && (
        <Row className="justify-content-center mt-4">
          <Col xs="12" md="8" className="text-center">
            <div className="alert alert-success">
              <h4><strong>ENTRADA CONFIRMADA</strong></h4>
              <p><strong>{signal.normal}</strong> ENTRADAS NORMAL</p>
              <p><strong>{signal.turbo}</strong> ENTRADAS NO TURBO</p>
              <p>O sinal ficará ativo por mais <strong style={{color: "red"}}>{countdown}</strong> segundos.</p>
            </div>
          </Col>
        </Row>
      )}
      <Row>
      <Col xs="12" className="p-0">
          <iframe
            height="700"
            title="Hacking Aviator"
            style={{ borderRadius: 40 }}
            width={window.screen.width}
            name="aviator"
            className="col-md-12 mt-2"
            src="https://br4bet.com/play/5498"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Col>
      </Row>
    </Container>
  );
};

export default CashMania;