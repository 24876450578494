import React from "react";
import { Row, Col, Button } from "reactstrap";
import FixedButtonsFooter from "components/FixedButtonsFooter/FixedButtonsFooter";

const Aulas = () => {
  return (
    <div className="content d-flex flex-column justify-content-center align-items-center mt-4 pt-4 pl-2 pr-2">
      {/* Título principal */}
      <Row className="d-flex justify-content-center align-items-center text-center w-100 mx-auto mb-4">
        <Col xs={12}>
          <h2 className="title">Bem-vindo ao CURSO AVIATOR GRATUITO!</h2>
          <p>
            Aqui você vai descobrir como fazer pelo menos <strong>R$1500</strong> por
            semana com o Aviator.
          </p>
        </Col>
      </Row>

      {/* Aulas */}
      <Row className="d-flex justify-content-center align-items-center w-100 mx-auto mb-4">
        <Col xs={12} md={8} className="text-center">
          <h3>AULA 1 - Boas-Vindas ao mercado de iGaming</h3>
          <p>Aperte o PLAY para assistir!</p>
          <div className="video-container mb-4">
            <iframe
              className="video"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/ToQT4bVv9Hk"
              title="AULA 1 - Boas-Vindas ao mercado de iGaming"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center w-100 mx-auto mb-4">
        <Col xs={12} md={8} className="text-center">
          <h3>AULA 2 - A plataforma que eu opero e suas vantagens</h3>
          <p>Aperte o PLAY para assistir!</p>
          <div className="video-container mb-4">
            <iframe
              className="video"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/6gBU6tQywGo"
              title="AULA 2 - A plataforma que eu opero e suas vantagens"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center w-100 mx-auto mb-4">
        <Col xs={12} md={8} className="text-center">
          <h3>AULA 3 - Como funciona o Aviator?</h3>
          <p>Aperte o PLAY para assistir!</p>
          <div className="video-container mb-4">
            <iframe
              className="video"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/keUukI4llpo"
              title="AULA 3 - Como funciona o Aviator?"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center w-100 mx-auto mb-4">
        <Col xs={12} md={8} className="text-center">
          <h3>AULA 4 - Mantendo a consistência em jogos</h3>
          <p>Aperte o PLAY para assistir!</p>
          <div className="video-container mb-4">
            <iframe
              className="video"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/06DuzYgLEcw"
              title="AULA 4 - Mantendo a consistência em jogos"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center w-100 mx-auto mb-4">
        <Col xs={12} md={8} className="text-center">
          <h3>BÔNUS - Mentalidade: A sua virada de chave</h3>
          <p>Aperte o PLAY para assistir!</p>
          <div className="video-container mb-4">
            <iframe
              className="video"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/wYp4iaUjX6s"
              title="BÔNUS - Mentalidade: A sua virada de chave"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Col>
      </Row>

      {/* Suporte */}
      <Row className="d-flex justify-content-center align-items-center w-100 mx-auto mb-4">
        <Col xs={12} className="text-center">
          <h3>Dúvidas e Problemas</h3>
          <Button color="success" href="https://wa.me/+5511971522258" target="_blank">
            Fale com o Suporte
          </Button>
        </Col>
      </Row>
      <Row>
        <FixedButtonsFooter />
      </Row>
    </div>
  );
};

export default Aulas;
