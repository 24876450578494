// Signals.jsx
import React from "react";
import FixedButtonsFooter from "../components/FixedButtonsFooter/FixedButtonsFooter";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

// Array de links com o atributo type
const links = [
  { path: "aviator-pro", className: "logo-aviator-pro", type: "CRASH_GAMES" },
  { path: "aviator-hack", className: "logo-aviator-10", type: "CRASH_GAMES" },
  { path: "dashboard-aviator", className: "logo-dashboard-aviator", type: "CRASH_GAMES" },
  { path: "fortune-tiger", className: "logo-fortune-tiger", type: "SLOTS" },
  { path: "cash-mania", className: "logo-cashmania", type: "SLOTS" },
  { path: "fortune-dragon", className: "logo-fortune-dragon", type: "SLOTS" },
  { path: "fortune-mouse", className: "logo-fortune-mouse", type: "SLOTS" },
  { path: "roleta-brasileira", className: "logo-roleta-br", type: "ROLETAS" },
  { path: "roleta-da-sorte", className: "logo-roleta-da-sorte", type: "ROLETAS" },
  { path: "roleta-ao-vivo", className: "logo-roleta-ao-vivo", type: "ROLETAS" },
  { path: "mines", className: "logo-mines", type: "OTHERS" },
];

// Função para filtrar links por tipo
const getLinksByType = (type) => links.filter((link) => link.type === type);

const Signals = () => {
  return (
    <div className="content p-4 mt-5">
      {/* Seção CRASH_GAMES */}
      <h4 className="mb-3">Crash Games</h4>
      <Row>
        {getLinksByType("CRASH_GAMES").map((link, index) => (
          <Col xs="4" key={index}>
            <Link to={link.path} className={`simple-text logo-mini ${link.className}`}></Link>
          </Col>
        ))}
      </Row>

      {/* Seção ROLETAS */}
      <h4 className="mt-5 mb-3">Roletas</h4>
      <Row>
        {getLinksByType("ROLETAS").map((link, index) => (
          <Col xs="4" key={index}>
            <Link to={link.path} className={`simple-text logo-mini ${link.className}`}></Link>
          </Col>
        ))}
      </Row>

      {/* Seção SLOTS */}
      <h4 className="mt-5 mb-3">Slots</h4>
      <Row>
        {getLinksByType("SLOTS").map((link, index) => (
          <Col xs="4" key={index} className="mb-3">
            <Link to={link.path} className={`simple-text logo-mini ${link.className}`}></Link>
          </Col>
        ))}
      </Row>

      {/* Seção OTHERS */}
      <h4 className="mt-5 mb-3">Outros Jogos</h4>
      <Row>
        {getLinksByType("OTHERS").map((link, index) => (
          <Col xs="4" key={index}>
            <Link to={link.path} className={`simple-text logo-mini ${link.className}`}></Link>
          </Col>
        ))}
      </Row>

      {/* Fixed Footer */}
      <Row>
        <FixedButtonsFooter />
      </Row>
    </div>
  );
};

export default Signals;
