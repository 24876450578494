import React, { useState, useEffect } from "react";
import { Row, Col, Button, Progress } from "reactstrap";
import { Link } from "react-router-dom";

import AviatorFrame from "components/AviatorFrame/AviatorFrame";
import { Enviroments } from "enviroments/enviroments";
import OnlineUsers from "components/OnlineUsers/OnlineUsers";

const RoletaDaSorte = () => {
  const [signal, setSignal] = useState(null);
  const [cooldown, setCooldown] = useState(0);
  const [loading, setLoading] = useState(false);

  const generateSignal = () => {
    const numbers = Array.from({ length: 5 }, () => Math.floor(Math.random() * 36)); // Gera 5 números aleatórios
    const sections = ["Vermelho", "Preto", "Orphelins", "Tiers du Cylindre", "Voisins du Zéro"];
    const columns = ["1ª Coluna", "2ª Coluna", "3ª Coluna"];

    return {
      mainNumber: numbers[0],
      coverage: numbers.slice(1), // Os 4 números restantes como cobertura
      section: sections[Math.floor(Math.random() * sections.length)],
      columnCoverage: [
        columns[Math.floor(Math.random() * columns.length)],
        columns[Math.floor(Math.random() * columns.length)]
      ],
    };
  };

  const handleRequestSignal = () => {
    setLoading(true);
    setTimeout(() => {
      const newSignal = generateSignal();
      setSignal(newSignal);
      setCooldown(120); // Cooldown de 2 minutos
      setLoading(false);
    }, 3000);
  };


  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => setCooldown((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [cooldown]);

  return (
    <div className="content mt-0 ml-0 mr-0 pl-1 pr-1">
      <Row className="d-flex justify-content-center align-items-center w-100 mx-auto">
        <Col xs={3}>
          <Link to="signals">
            <div className="d-flex justify-content-center align-items-center">
              <i aria-hidden="true" className="fas fa-arrow-left fs-xxl"></i>
              <span className="ml-2">voltar</span>
            </div>
          </Link>
        </Col>
        <Col xs={9}>
          <h3 className="title m-0 text-right">I.A. Roleta da Sorte PRO</h3>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center mt-2">
        <Col xs="auto" className="text-center">
          <OnlineUsers />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs="12" md="8" className="text-center">
          <p className="text-muted">
            Clique em "Solicitar Sinal" para receber uma recomendação de aposta para race e colunas.
          </p>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mt-3">
        <Col xs="12" md="6" className="text-center">
          <Button
            color="primary"
            onClick={handleRequestSignal}
            disabled={cooldown > 0 || loading}
          >
            {loading ? "Buscando sinal..." : "Solicitar Sinal"}
          </Button>
        </Col>
      </Row>
      {signal && (
        <Row className="d-flex justify-content-center mt-4">
          <Col xs="12" md="6" className="text-center">
            <h4 className="text-success">SINAL CONFIRMADO</h4>
            <p>
              🎯 Apostar no número <strong style={{fontSize: 20}}>{signal.mainNumber}</strong>
            </p>
            <p>
              Race: <strong>6 ou 7</strong>
            </p>
            <p>
              Colunas sugeridas: <strong>{signal.columnCoverage.join(" e ")}</strong>
            </p>
            <p>
              Proteção no número: <strong>0</strong>
            </p>
            <p>
              Fazer até 3 entradas. (Gale Opcional)
            </p>
          </Col>
        </Row>
      )}
      {cooldown > 0 && (
        <Row className="d-flex justify-content-center mt-3">
          <Col xs="12" md="6" className="text-center">
            <p className="text-danger">
              Tempo para próximo sinal: {Math.floor(cooldown / 60)}:
              {(cooldown % 60).toString().padStart(2, "0")}
            </p>
            <Progress value={(120 - cooldown) / 120 * 100} />
          </Col>
        </Row>
    )}

    <Row className="mt-2">
        <Col>
          <AviatorFrame title="Aviator" src={Enviroments.IFRAME_ROLETA_SORTE_EZUGI} /> 
        </Col>
      </Row>
    </div>
  );
};

export default RoletaDaSorte;
