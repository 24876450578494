export const Enviroments = {
  API_URL: "https://api-ia-teleco-66be31886130.herokuapp.com/",
  API_URL_MINES: "https://api-ia-teleco-66be31886130.herokuapp.com/",
  API_URL_NODE: "https://api-aviator-cb5db3cad4c0.herokuapp.com/",
  IFRAME_AVIATOR: "https://go.aff.br4-partners.com/l59wgsqt?utm_campaign=ia-teleco-av",
  IFRAME_FORTUNE_TIGER: "https://go.aff.br4-partners.com/3faibgrt?utm_campaign=ia-teleco-tigri",
  IFRAME_DRAGON_FORTUNE: "https://br4bet.com/play/5484",
  IFRAME_ROLETA_BRASILEIRA_PRAGMA: "https://go.aff.br4-partners.com/sv72lxgy?utm_campaign=ia-teleco-bra",
  IFRAME_ROLETA_BRASILEIRA2: "",
  IFRAME_ROLETA_SORTE_EZUGI: "https://go.aff.br4-partners.com/c4rs113z?utm_campaign=ia-teleco-ezu",
  IFRAME_ROLETA_AOVIVO_EVOLUTION: "https://go.aff.br4-partners.com/82icto3v?utm_campaign=ia-teleco-evo",
  IFRAME_MINES: "https://go.aff.br4-partners.com/oic22ui6?afp=ia-teleco-mines",
  IFRAME_JETX: "",
  IFRAME_AVIATRIX: "",
  IFRAME_CASHMANIA: "",
  IFRAME_FORTUNE_MOUSE: "https://go.aff.br4-partners.com/ios4usfc?afp=ia-teleco-mouse",
  IFRAME_SPACEMAN: "https://go.aff.br4-partners.com/jtpknhtd?afp=ia-teleco-spaceman"
};
