import React, { useState, useEffect } from "react";
import { Row, Col, Button, Alert } from "reactstrap";
import FixedButtonsFooter from "components/FixedButtonsFooter/FixedButtonsFooter";
import axios from "axios";

import { Enviroments } from "../enviroments/enviroments";

const Robo = () => {
  const [robo1Status, setRobo1Status] = useState(false);
  const [robo10Status, setRobo10Status] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertColor, setAlertColor] = useState("");

  useEffect(() => {
    const fetchRoboStatus = async () => {
      const userName = localStorage.getItem("name");

      if (!userName) {
        setAlertMessage("Usuário não encontrado no localStorage.");
        setAlertColor("danger");
        return;
      }

      try {
        const response1 = await axios.get(`${Enviroments.API_URL_NODE}robo-status`, {
          params: {
            usuario: userName,
            nomeRobo: "ROBO_1.5X"
          }
        });

        const response10 = await axios.get(`${Enviroments.API_URL_NODE}robo-status`, {
          params: {
            usuario: userName,
            nomeRobo: "ROBO_10X"
          }
        });

        if (response1.status === 200) {
          setRobo1Status(response1.data.status);
        }

        if (response10.status === 200) {
          setRobo10Status(response10.data.status);
        }
      } catch (error) {
        setAlertMessage("Erro ao buscar o status dos robôs.");
        setAlertColor("danger");
        console.error("Erro ao buscar o status dos robôs:", error);
      }
    };

    fetchRoboStatus();
  }, []);

  const handleRoboToggle = async (robo, status) => {
    const url = "toggle-robo";
    const userName = localStorage.getItem("name");

    const payload = {
      nomeRobo: robo === 1.5 ? "ROBO_1.5X" : "ROBO_10X",
      usuario: userName || "Anonimo",
      status: status
    };

    try {
      const response = await axios.post(`${Enviroments.API_URL_NODE}${url}`, payload);
      if (response.status === 200) {
        setAlertMessage(`Robô ${robo} ${status ? "ativado" : "desativado"} com sucesso!`);
        setAlertColor("success");
        if (robo === 1.5) {
          setRobo1Status(status);
        } else {
          setRobo10Status(status);
        }
      }
    } catch (error) {
      setAlertMessage(`Erro ao ${status ? "ativar" : "desativar"} o Robô ${robo}.`);
      setAlertColor("danger");
      console.error("Erro ao enviar o status do robô:", error);
    }
  };

  return (
    <div className="content d-flex flex-column justify-content-center align-items-center mt-4 pt-4 pl-2 pr-2">
      {alertMessage && (
        <Alert color={alertColor} toggle={() => setAlertMessage(null)}>
          {alertMessage}
        </Alert>
      )}
      <Row className="mb-3">
        <Col className="d-flex flex-column">
          <label>Controle do Robô 1.5X</label>
          <Button
            color={robo1Status ? "danger" : "success"}
            onClick={() => handleRoboToggle(1.5, !robo1Status)}
          >
            {robo1Status ? "Desativar Robo 1.5X" : "Ativar Robo 1.5X"}
          </Button>
        </Col>
        <Col className="d-flex flex-column">
          <label>Controle do Robô 10X</label>
          <Button
            color={robo10Status ? "danger" : "success"}
            onClick={() => handleRoboToggle(10, !robo10Status)}
          >
            {robo10Status ? "Desativar Robo 10X" : "Ativar Robo 10X"}
          </Button>
        </Col>
      </Row>
      <Row>
        <FixedButtonsFooter />
      </Row>
    </div>
  );
};

export default Robo;
