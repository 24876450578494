import React from 'react';

const AviatorFrame = ({ src, title }) => (
  <iframe
    className="pr-2 pl-2"
    height="700"
    title={title}
    style={{ borderRadius: 40, border: "2px solid #ba54f5" }}
    width={window.innerWidth}
    src={src}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
);

export default AviatorFrame;
