import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Label,
  Form,
  FormGroup,
  Input,
  Col,
  Alert
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Enviroments } from "../enviroments/enviroments";

function Login(props) {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleRecaptchaChange = token => {
    setRecaptchaToken(token);
  };

  async function handleSubmit(event) {
    setError(null);
    event.preventDefault();

    if (!email || !password) {
      setError("Por favor, preencha o email e senha");
      return;
    }

    if (!recaptchaToken) {
      setError("Por favor, complete o reCAPTCHA");
      return;
    }

    setIsSubmitting(true);

    const loginData = {
      email,
      password,
      recaptchaToken
    };

    try {
      const res = await axios.post(
        `${Enviroments.API_URL_MINES}auth/login`,
        loginData
      );
      localStorage.setItem("apiToken", res.data.token);
      localStorage.setItem("expires", res.data.expiresIn);
      localStorage.setItem("expirationDate", res.data.expirationDate);
      localStorage.setItem("role", res.data.role);
      localStorage.setItem("name", res.data.userName);
      localStorage.setItem("userId", res.data.userId);
      localStorage.setItem("role", res.data.role);
      console.log(res.data);
      localStorage.setItem("email", email);
      history.push("/admin/dashboard");
    } catch (err) {
      setError("Usuário ou senha inválido");
      setIsSubmitting(false);
    }
  }

  return (
    <div>
      <Col className="ml-auto mr-auto col-md-6 col-lg-5">
        <Card>
          <Form>
            <CardHeader>
              <CardTitle tag="h3">Login</CardTitle>
            </CardHeader>
            <CardBody>
              <Alert isOpen={error != null} color="danger">
                {error}
              </Alert>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  defaultValue="Write your Email here"
                  placeholder="Email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </FormGroup>

              <FormGroup>
                <Label>Password</Label>
                <Input
                  defaultValue="Write your password here"
                  placeholder="Password"
                  type="password"
                  autoComplete="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </FormGroup>

              <a
                style={{ fontSize: 12 }}
                target="_blank"
                rel="noreferrer"
                href="https://iateleco.com/auth/register/ROLE_TELECO"
              >
                Não possui acesso? Clique aqui!
              </a>

              <div className="mt-4">
                <ReCAPTCHA
                  sitekey="6LcUHf8pAAAAAAREXjteyj2WlMUMwF0daxokfITr"
                  onChange={handleRecaptchaChange}
                />
              </div>
            </CardBody>
            <CardFooter>
              <Button
                className="btn-fill"
                color="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                Login
                {isSubmitting ? "..." : ""}
              </Button>
            </CardFooter>
          </Form>
        </Card>
      </Col>
    </div>
  );
}

export default Login;
